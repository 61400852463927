import React from "react"
import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import HubSpotForm from "../components/HubSpotForm"
import HeaderImage from "../assets/img/new/tlo-serch-01.webp"
import { devMode } from "../components/Variables"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

i18n.changeLanguage("en")

const SeoConsultation = () => {
  const { t } = useTranslation()

  return (
    <Layout
      seo={{
        title: "Free quotation for website optimisation and positioning • WeNet.pl",
        description:
          "Get a free quotation for optimisation and positioning of your website • Fill in the form and ensure high page ranks on Google.",
        hreflangs: {
          pl: "kontakt/",
          en: "en/contact/",
          de: "de/kontakt/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Home",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/en/contact/",
                name: t("seo-title-contact"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              {
                url: t("link-url-free-seo-consultation"),
                text: t("link-text-free-seo-consultation"),
              },
            ]}
          />
        </div>{" "}
        <h1 className="title text-center">Free quotation for website optimization and positioning</h1>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12"></div>
            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1 offset-lg-0 col-12">
              <p className="text-blue font-18" style={{ marginTop: "30px" }}>
                Looking for a free quotation? Fill in the form and ensure higher page ranks of your website on Google.
                Our consultant will contact you with a detailed offer – completely free of charge.
                <br />
                <br />
              </p>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <h2>Application form</h2>
              <HubSpotForm
                formId={
                  devMode === "true" ? "785f39cf-fb1c-4885-ba0d-b0d11ba93914" : "75c1a8b5-9a5b-4e3a-839b-389e21c1c01c"
                }
                formType="contact"
              />
            </div>
            <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <p className="text-blue font-18" style={{ marginTop: "33px" }}>
                Leave your details here to receive:
                <br />
                <br />
              </p>
              <ul style={{ marginBottom: "45px" }}>
                <li>
                  exact pricing of positioning and optimization – you will know precisely what you are investing in –
                  there are no hidden costs with us,
                </li>
                <li>
                  details of the offer – you will find out exactly what the offer prepared by our team contains – we are
                  honest about what can we do for your company,
                </li>
                <li>
                  access to an expert – you will be put in contact with recommended SEO experts – we are happy to share
                  our knowledge and give advice tailored to the needs of our customers,
                </li>
                <li>
                  competitive advantage – you will find out what can be improved so that your website outperforms your
                  competitors – we will ensure better visibility of your website in the search engine.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SeoConsultation
